exports.components = {
  "component---src-gatsby-templates-blog-category-js": () => import("./../../../src/gatsby-templates/BlogCategory.js" /* webpackChunkName: "component---src-gatsby-templates-blog-category-js" */),
  "component---src-gatsby-templates-blog-js": () => import("./../../../src/gatsby-templates/Blog.js" /* webpackChunkName: "component---src-gatsby-templates-blog-js" */),
  "component---src-gatsby-templates-blogs-js": () => import("./../../../src/gatsby-templates/Blogs.js" /* webpackChunkName: "component---src-gatsby-templates-blogs-js" */),
  "component---src-gatsby-templates-campaign-js": () => import("./../../../src/gatsby-templates/Campaign.js" /* webpackChunkName: "component---src-gatsby-templates-campaign-js" */),
  "component---src-gatsby-templates-campaigns-js": () => import("./../../../src/gatsby-templates/Campaigns.js" /* webpackChunkName: "component---src-gatsby-templates-campaigns-js" */),
  "component---src-gatsby-templates-donation-js": () => import("./../../../src/gatsby-templates/Donation.js" /* webpackChunkName: "component---src-gatsby-templates-donation-js" */),
  "component---src-gatsby-templates-home-js": () => import("./../../../src/gatsby-templates/Home.js" /* webpackChunkName: "component---src-gatsby-templates-home-js" */),
  "component---src-gatsby-templates-internship-js": () => import("./../../../src/gatsby-templates/Internship.js" /* webpackChunkName: "component---src-gatsby-templates-internship-js" */),
  "component---src-gatsby-templates-internships-js": () => import("./../../../src/gatsby-templates/Internships.js" /* webpackChunkName: "component---src-gatsby-templates-internships-js" */),
  "component---src-gatsby-templates-project-js": () => import("./../../../src/gatsby-templates/Project.js" /* webpackChunkName: "component---src-gatsby-templates-project-js" */),
  "component---src-gatsby-templates-projects-js": () => import("./../../../src/gatsby-templates/Projects.js" /* webpackChunkName: "component---src-gatsby-templates-projects-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-app-index-js": () => import("./../../../src/pages/app/index.js" /* webpackChunkName: "component---src-pages-app-index-js" */),
  "component---src-pages-borrow-an-intern-js": () => import("./../../../src/pages/borrow-an-intern.js" /* webpackChunkName: "component---src-pages-borrow-an-intern-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-enroll-js": () => import("./../../../src/pages/enroll.js" /* webpackChunkName: "component---src-pages-enroll-js" */),
  "component---src-pages-partner-js": () => import("./../../../src/pages/partner.js" /* webpackChunkName: "component---src-pages-partner-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-transparency-js": () => import("./../../../src/pages/transparency.js" /* webpackChunkName: "component---src-pages-transparency-js" */)
}

