import { Script } from "gatsby";
import React from "react";
import "./src/styles/global.css";

export const wrapPageElement = ({ element }) => {
  return (
    <>
      {element}
      {/* <Script src='https://rsms.me/inter/inter.css' /> */}

      {/* <Script id='givebutter-id'>
        {`window.Givebutter=window.Givebutter||function(){(Givebutter.q=Givebutter.q||[]).push(arguments)};Givebutter.l=+new Date;
    window.Givebutter('setOptions',
    {
        "accountId": "h7XIRt3Ju5Pifzj6"
    });`}
      </Script>
      <Script
        async
        strategy='off-main-thread'
        src='https://js.givebutter.com/elements/latest.js'
      ></Script> */}
    </>
  );
};
